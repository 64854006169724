import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import useOmdDatasetSize from '../components/hooks/use-omd-dataset-size'
import { graphql } from "gatsby"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {

  const lastArticle = data.allArticles.edges[0].node.result[data.allArticles.edges[0].node.result.length - 1]

  return (
    <Layout>
      <Seo
        title='Welcome to the Open Membrane Database' />
      <section
        className="hero is-medium"
        style={{
          background:
            "linear-gradient(rgba(238, 241, 251, 0.96), rgba(238, 241, 251, 0.96)), url('https://openmembranedatabase.org/static/open-membrane-database-logo-without-text-13a47a5dd9b201ce7bbe4e4b7255be3c.png')",
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-8 is-variable">
              <div className="column is-8">
                <h1 className="title is-2">The Open Membrane Database</h1>
                <p className="subtitle">
                  An international collaboration for benchmarking and analyzing water purification and desalination membranes
                </p>
                <Link to="/reverse-osmosis-database" className="button is-primary">Explore the RO database</Link>
              </div>
              <div className="column is-flex is-flex-direction-column is-justify-content-space-between ">
                <div className="box">
                  <article>
                    <h1 className="title is-6">
                      {lastArticle.meta.title}
                    </h1>
                    <p className="subtitle is-7">{new Date(lastArticle.dates.publication).toLocaleDateString()}</p>
                    <p>{lastArticle.meta.description}</p>
                  </article>
                  <div className='mt-5'>
                    <Link to={`/news/${lastArticle.meta.slug}`} className="button is-primary mr-3 mb-3 is-small">Read more</Link>
                    <Link to='/news' className="button is-primary is-outlined is-small">All news</Link>
                  </div>
                </div>
                <div className="box">
                  <article>
                    <h1 className="title is-6">
                      The OMD Paper is out !
                    </h1>
                    <a href='https://www.sciencedirect.com/science/article/pii/S037673882100870X' className="button is-primary mr-3 is-small" target='_blank'  rel='noreferrer'>Read the paper</a>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <div className="columns is-vcentered">
            <div className="column">
              <div className="box">
                <StaticImage
                  src="../images/reverse-osmosis-database-chart-from-the-open-membrane-database.png"
                  alt="Picture of the Open Membrane Database's Reverse Osmosis database with chart, filters and table"
                  width={2085 / 2}
                  height={2013 / 2}
                />
              </div>
            </div>
            <div className="column">
              <h2 className="title is-5">Explore  {Math.floor(useOmdDatasetSize() / 100) * 100 + "+"} commercial and lab-made membranes</h2>
              <p className="subtitle is-6">Find permeability and selectivity parameters, membrane chemistry, publication reference, etc...</p>
              <Link to="/reverse-osmosis-database" className="button is-primary mx-2 mt-2">Explore the RO database</Link>
              <Link to="/submit-a-membrane" className="button is-outlined is-primary mx-2 mt-2">Submit a membrane</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column is-flex is-flex-direction-column is-justify-content-space-between mb-5">
              <div>
                <h2 className="title is-5">New to membrane science ?</h2>
                <p className="subtitle is-6">Check out some of the basic principles here</p>
              </div>
              <div className="pt-3 pb-5">
                <StaticImage
                  style={{ maxWidth: 200 }}
                  src="../images/membrane_filtration.png"
                  alt="Open Membrane Database Reverse Osmosis membrane filtration illustration"
                />
              </div>
              <div>
                <Link to="/membrane-science" className="button is-primary">Read the basics</Link>
              </div>
            </div>
            <div className="column is-flex is-flex-direction-column is-justify-content-space-between mb-5">
              <div>
                <h2 className="title is-5">Latest news</h2>
                <article>
                  <h1 className="title is-6">
                    {lastArticle.meta.title}
                  </h1>
                  <p className="subtitle is-7">{new Date(lastArticle.dates.publication).toLocaleDateString()}</p>
                  <p>{lastArticle.meta.description}</p>
                </article>
              </div>
              <div>
                <Link to={`/news/${lastArticle.meta.slug}`} className="button is-primary mr-3">Read more</Link>
                <Link to='/news' className="button is-primary is-outlined">All news</Link>
              </div>
            </div>
            <div className="column is-flex is-flex-direction-column is-justify-content-space-between mb-5">
              <div>
                <h2 className="title is-5">The Open Membrane Database</h2>
                <p className="subtitle is-6">An open-access platform based on user contributions</p>
              </div>
              <div className="pt-3 pb-5">
                <StaticImage
                  style={{ maxWidth: 300 }}
                  alt="Open Membrane Database logo"
                  src="../images/open-membrane-database-logo-without-text.png"
                />
              </div>
              <div>
                <Link to="/about-the-open-membrane-database" className="button is-primary mx-2 mt-2">About</Link>
                <Link to="/about-the-open-membrane-database#contact-us" className="button is-primary is-outlined mx-2 mt-2">Contact</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query {
  allArticles {
    edges {
      node {
        result {
          dates {
            publication
          }
          meta {
            description
            title
            slug
          }
        }
      }
    }
  }
}

`