import { graphql, useStaticQuery } from 'gatsby'

const useOmdDatasetSize = () => {

    const dataset = useStaticQuery(graphql` 
        query test {
            allMembranes {
                edges {
                    node {
                        result {
                            _id
                        }
                    }
                }
            }
        }
    `)

    return dataset.allMembranes.edges[0].node.result.length
}

export default useOmdDatasetSize